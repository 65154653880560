@font-face {
    font-family: "Praktika";
    src: url("../assets/font/Praktika_Regular.otf") format("opentype");
    font-weight: normal;
    font-style: normal;
  }
  
  
@font-face {
  font-family: "Praktika";
  src: url("../assets/font/Praktika_Bold.otf") format("opentype");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "Praktika";
  src: url("../assets/font/Praktika_Italic.otf") format("opentype");
  font-weight: normal;
  font-style: italic;
}


.App{
    min-height: 100dvh;
}

.myunderline{
  background: url('../assets/images/underline.svg') bottom left no-repeat;
  background-size: 100% 10px;
  padding-bottom: 8px;
}

.topline{
  background: url('../assets/images/house.svg') top  no-repeat;
  background-size: 80% 100%;
  padding-top: 55px;
}

@media screen and (max-width:750px){
    .NavBar-CTA{
        display: none !important;
    }
}

/* a:visited{
  color: green;
} */