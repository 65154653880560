    @keyframes slide {
    from {
      transform: translateX(0);
    }
    to {
      transform: translateX(-100%);
    }
  }

  @keyframes slide-invert {
    from {
      transform: translateX(-100%);
    }
    to {
      transform: translateX(0%);
    }
  }

  
  .logos {
    overflow: hidden;
    padding: 15px 0;
    white-space: nowrap;
    position: relative;
    width: 100%;
    transition: opacity 700ms ease-in-out;
    will-change: opacity;
    mask-image: linear-gradient(90deg, transparent 5%, rgba(23, 23, 23, 1) 10%, rgba(23, 23, 23, 1) 90%, transparent 95%)    
  }
  
 
  
  .logos-slide {
    display: inline-block;
    animation: 40s slide infinite linear;
  }
  
  .invert-slide {
    animation: 40s slide-invert infinite linear;
  }

  .logos-slide img {
    object-fit: cover;
    margin: 0 15px;
    transition: all 0.2s linear;
    border-radius: 28px;
  }

  .logos-slide img:hover {
    /* scale: 1.1; */
    /* transform: rotate(2deg);
    border-radius: 14px; */
  }